import { Link } from '@remix-run/react'

import style from './Footer.module.css'
import { termsLink } from '../../forms/LegalTerms'

const FooterLinks: React.FC = () => {
  return (
    <ul className={style.footerLinks}>
      {/* <li>
        <Link to="/about" className={styles.inlineFooterLink}>
          About
        </Link>
      </li> */}

      <li>
        <a
          className={style.inlineFooterLink}
          rel="noopener noreferrer"
          target="_blank"
          href="https://discord.gg/2sTUFtcnP2"
        >
          <i className="fab fa-discord" /> Community
        </a>
      </li>

      <li>
        <Link to="/support" className={style.inlineFooterLink}>
          Support
        </Link>
      </li>

      <li>
        <a
          className={style.inlineFooterLink}
          rel="noopener noreferrer"
          target="_blank"
          href="https://yupty.notion.site/Public-press-page-27ea4e55f4544c9aaffdf6f2bb6f2033"
        >
          Press
        </a>
      </li>

      <li>
        <a
          className={style.inlineFooterLink}
          rel="noopener noreferrer"
          target="_blank"
          href={termsLink}
        >
          Terms & conditions
        </a>
      </li>

      <li>
        <Link className={style.inlineFooterLink} to="/privacy">
          Privacy
        </Link>
      </li>
    </ul>
  )
}

// const CopyrightNotice: React.FC = (props) => {
//   const currentYear = new Date().getFullYear()

//   return (
//     <div className={styles.copyrightNotice} {...props}>
//       &copy; {currentYear} Yupty ltd
//     </div>
//   )
// }

export const InlineFooter: React.FC = (props) => {
  return (
    <div className={style.inlineFooter} {...props}>
      <FooterLinks />
    </div>
  )
}

export const FixedBottomFooter = () => {
  return (
    <div className={style.fixedBottomFooter}>
      <div className={style.fixedBottomFooterInner}>
        <FooterLinks />
      </div>
    </div>
  )
}
