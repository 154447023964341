import { FixedBottomFooter } from './Footer'

import styles from './Footer.module.css'

const DesktopFooter: React.FC = () => {
  return (
    <div className={styles.hideBelowMedium}>
      <FixedBottomFooter />
    </div>
  )
}

export default DesktopFooter
