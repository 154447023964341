import { css } from '@emotion/react'

import {
  Dialog,
  DialogTrigger,
  Popover as ReactAriaPopover,
  PopoverProps as ReactAriaPopoverProps,
} from 'react-aria-components'
import { mTheme } from '../style/themes'

let styles = {
  container: css({
    display: 'flex',
    color: mTheme.fontColor.plain.primary,
    gap: mTheme.size.S,
  }),
  popoverButton: css({
    forcedColorAdjust: 'none',
    borderRadius: mTheme.radius.primary,
    border: 'none',
    padding: mTheme.size.S,
    fontSize: mTheme.fontSize.S,
    boxSizing: 'content-box',
    alignSelf: 'right',

    '&[data-pressed]': {
      boxShadow: 'none',
      background: mTheme.backgroundColor.primary,
      color: mTheme.fontColor.plain.tertiary,
    },
  }),

  popover: css({
    maxWidth: 'unset',
    maxHeight: '100%',
    background: mTheme.backgroundColor.primary,
    border: `2px solid ${mTheme.borderColor.secondary}`,
    borderRadius: mTheme.radius.primary,
    boxShadow: `${mTheme.backgroundColor.primary} 0px 8px 24px`,
  }),

  arrow: css({
    fontSize: mTheme.fontSize.XS,
    color: mTheme.fontColor.plain.tertiary,
    marginBottom: mTheme.size.XXS,
  }),
  dialog: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  hasChanged: css({
    color: mTheme.color.primary,
  }),
}

interface PopoverProps extends Omit<ReactAriaPopoverProps, 'children'> {
  children: React.ReactNode
  button: React.ReactNode
}

export function Popover({ children, button, ...props }: PopoverProps) {
  return (
    <DialogTrigger css={styles.container} {...props}>
      {button}
      <ReactAriaPopover css={styles.popover} placement="bottom">
        <Dialog css={styles.dialog}>{children}</Dialog>
      </ReactAriaPopover>
    </DialogTrigger>
  )
}
