export const link: StyleWithOptions<{
  isInMobileMenu?: boolean
  isFirst?: boolean
  flexDirection?: 'row' | 'column'
}> =
  ({ isInMobileMenu = false, flexDirection = 'column' }) =>
  ({ fontColor, color, radius, backgroundColor, size, breakpoint }) => ({
    textAlign: 'center',
    color: fontColor.plain.primary,
    display: 'flex',
    flexDirection,
    gap: size.XXS,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    letterSpacing: '0.07rem',
    padding: 0,
    ':visited': {
      color: fontColor.plain.primary,
    },

    '&.active': {
      color: color.primary,
    },

    [breakpoint.medium]: {
      ':hover': {
        color: fontColor.plain.primary,
      },
      ':hover:visited': {
        color: color.primary,
      },
    },
    '&>i': {
      height: 'fit-content',
    },
    '&>span': {
      margin: 0,
    },

    ...(isInMobileMenu && {
      flexDirection: 'row',
      justifyContent: 'start',
      textAlign: 'left',
      backgroundColor: backgroundColor.secondary,
      borderRadius: radius.primary,
      color: fontColor.plain.secondary,
      fontWeight: 'bold',
      padding: size.S,
      width: '100%',
    }),
  })

export const icon: StyleWithOptions<boolean> =
  (isInMobileMenu) =>
  ({ fontSize, size }) => ({
    fontSize: fontSize.M,
    ...(isInMobileMenu && { height: size.L }),
  })

export const label: StyleWithOptions<boolean> =
  (isInMobileMenu) =>
  ({ fontSize, size, breakpoint }) =>
    isInMobileMenu
      ? {
          marginLeft: size.XS,
        }
      : {
          fontSize: fontSize.S,
          marginTop: size.XXS,

          [breakpoint.small]: {
            fontSize: fontSize.S,
          },
        }
