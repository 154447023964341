import { Command } from 'react-command-palette'
import { CommandContext } from './CommandPalette'

export function createNavigationCommands(context: CommandContext): Command[] {
  // Ignore routes with parameters for now, e.g. /venues/:venueSlug
  const nonParameterizedRoutes = context.rootRouteConfigs.filter(
    (route) => !route.path.includes(':'),
  )

  return nonParameterizedRoutes.map((route, index) => ({
    id: index,
    color: 'red',
    name: `Navigate to ${route.label}`,
    command: () => {
      context.navigate(route.path)
      return true
    },
  }))
}
