import React, { useState } from 'react'

import { useCurrentUser } from '../../../graphql/hooks'
import MobileMenu from '../MobileMenu'
import { ButtonAsLink } from '../../Button'
import { HeaderButton, MenuButton } from './HeaderButton'
import HeaderLogo from './HeaderLogo'

import * as styles from './Header.styles'
import { Beta, useBetas } from '../../../utilities/useBeta'
import { SelectCityMutatingCompact } from '../../forms/SelectCity/SelectCity'
import { SelectedProfileLink } from './ProfileButton/ProfileButton'
import { loggedOutRightSectionItems, navButtonConfigs } from './navConfig'
import { OverflowMenu } from './ProfileButton/OverflowMenu'

let Header: React.FC = () => {
  let { selectedProfile, isAuthenticated } = useCurrentUser()
  let profileTypename = selectedProfile?.__typename || 'User'

  let navConfigs = navButtonConfigs[profileTypename]

  let navItems = navConfigs.filter((item) => {
    if (typeof item.authenticated === 'boolean') {
      return item.authenticated === isAuthenticated
    }

    return true
  })

  let navBetaNames = navItems
    .filter((item) => item.beta)
    .map((item) => item.beta) as Beta[]
  let betas = useBetas(navBetaNames)

  let overflowItems = navItems.filter(
    (item) => item.overflow && (!item.beta || betas[item.beta]),
  )

  let prominentNavElements = navItems.map((item, i) => {
    let navStyles = item.overflow && !item.desktop ? [styles.hidden] : []

    if (isAuthenticated) {
      navStyles.push(styles.authenticatedHeaderItem)
    }

    let key = item.label + item.iconName + i

    if (item.beta && !betas[item.beta]) {
      return null
    } else if (!item.element) {
      return (
        <div css={navStyles} key={key}>
          <HeaderButton {...item} />
        </div>
      )
    } else if (item.element) {
      return (
        <div css={navStyles} key={key}>
          {item.element}
        </div>
      )
    }

    return null
  })

  let [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
  let toggleMenu = () => {
    setIsMenuExpanded(!isMenuExpanded)
  }
  let hideMenu = () => setIsMenuExpanded(false)

  let LoggedInContent = () =>
    isAuthenticated && (
      <div
        css={[styles.headerInnerLoggedIn, !isAuthenticated && styles.hidden]}
      >
        <div css={styles.desktopLeftSection}>
          <HeaderLogo size={45} />
          <SelectCityMutatingCompact />
        </div>

        <div css={styles.rightSectionLoggedIn}>
          {prominentNavElements}

          <div css={styles.hiddenAboveMedium}>
            <MenuButton
              css={[styles.headerItem]}
              isOpen={isMenuExpanded}
              toggle={toggleMenu}
            />
          </div>

          <div css={styles.hiddenBelowMedium}>
            <OverflowMenu items={overflowItems} />
          </div>
          <div css={styles.hiddenBelowMedium}>
            {selectedProfile && (
              <SelectedProfileLink
                profile={selectedProfile}
                composeCss={styles.selectedProfileButton}
              />
            )}
          </div>
        </div>
      </div>
    )

  let LoggedOutContent = () =>
    !isAuthenticated && (
      <div css={styles.headerInnerLoggedOut}>
        <HeaderLogo size={45} />

        <div css={styles.leftSection}>{prominentNavElements}</div>

        <div css={styles.rightSectionLoggedOut}>
          {loggedOutRightSectionItems.map(({ label, ...item }) => (
            <ButtonAsLink
              to={item.route}
              key={item.route}
              {...item}
              css={styles.rightSectionButton}
              plain
            >
              {label}
            </ButtonAsLink>
          ))}
          <div css={styles.hiddenAboveMedium}>
            <MenuButton
              css={styles.headerItem}
              isOpen={isMenuExpanded}
              toggle={toggleMenu}
              hideIcon
            />
          </div>
        </div>
      </div>
    )

  return (
    <>
      <section css={styles.headerOuter}>
        <LoggedInContent />
        <LoggedOutContent />

        <MobileMenu
          mobileMenuItems={overflowItems}
          isMenuExpanded={isMenuExpanded}
          hideMenu={hideMenu}
        />
      </section>
    </>
  )
}

export default Header
