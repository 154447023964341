import React from 'react'
import { NavLink } from '@remix-run/react'

import { getProfileUrl } from '../../../../utilities/userUtils'
import { UserProfile } from '../../../../graphql/fragments/userDetails.fragment'

import * as styles from './ProfileButton.styles'

export let SelectedProfileLink: React.FC<{
  profile?: UserProfile
  composeCss?: Style
}> = ({ profile, composeCss }) => {
  if (!profile || profile.__typename === 'User') {
    return null
  }

  return (
    <NavLink
      prefetch="render"
      to={getProfileUrl(profile)}
      css={[styles.link, composeCss]}
    >
      {profile?.picture && <div css={styles.thumbnail(profile.picture)} />}
      <span>{profile?.name}</span>
    </NavLink>
  )
}
