import React, { useCallback, useState } from 'react'
import { HeaderButton, MenuButton } from '../HeaderButton'
import { Popover } from '../../../Popover'
import { SelectProfilesList } from './SelectProfilesList'
import * as styles from './ProfileButton.styles'
import { NavButtonConfig } from '../navConfig'

type Props = {
  items: NavButtonConfig[]
}

export let OverflowMenu: React.FC<Props> = (props) => {
  let [isOpen, setIsOpen] = useState(false)
  let toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={toggle}
      button={<MenuButton isOpen={isOpen} toggle={toggle} />}
    >
      {props.items.map((item, index) => (
        <HeaderButton key={index} {...item} composeCss={styles.headerButton} />
      ))}
      <SelectProfilesList buttonCss={styles.headerButton} />
    </Popover>
  )
}
