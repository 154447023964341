import { css } from '@emotion/react'
import { mTheme } from '../../../style/themes'

let {
  breakpoint,
  backgroundColor,
  borderColor,
  size,
  fontSize,
  fontColor,
  transition,
  radius,
} = mTheme

export let headerItem: Style = css({
  zIndex: 9999,
  textAlign: 'center',
  color: fontColor.plain.secondary,
  display: 'block',

  '&:hover > *': {
    color: 'white !important',
    transition: transition.hoverColor,
  },

  'i, button > i': {
    fontSize: fontSize.L,
  },

  h6: {
    color: fontColor.plain.secondary,
    fontSize: size.S,
  },

  'h1, h2, h3, h4, h5, h6': {
    margin: 0,
  },

  [breakpoint.small]: {
    lineHeight: size.S,
    padding: size.XS,

    i: {
      fontSize: '1.25rem',
    },

    h6: {
      fontSize: '0.75rem',
      marginTop: '0.2rem',
    },
  },

  [breakpoint.medium]: {
    background: 'pink',
    margin: `0 ${size.S} 0 0`,
    padding: size.XS,
  },
})

export let rightSectionButton = css({
  // fontWeight: 'bold',
  letterSpacing: '0.07rem',
  fontSize: fontSize.S,
  padding: size.XS,

  [breakpoint.medium]: {
    padding: 0,
  },
})

export let profileThumb: StyleWithOptions<string> = (imageUrl) =>
  css({
    width: size.L,
    height: size.L,
    borderRadius: radius.primary,
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
  })

export let profileLink: Style = ({ size, breakpoint }) => ({
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  marginLeft: size.S,
  whiteSpace: 'nowrap',

  h6: {
    margin: 0,
  },

  div: {
    marginRight: size.S,
  },

  [breakpoint.small]: {
    display: 'none',
  },
})

export let headerOuter: Style = css({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 9999,
  backgroundColor: backgroundColor.primary,
  borderBottom: `1.6px solid ${borderColor.secondary}`,
  padding: `0 ${size.S}`,

  [breakpoint.small]: {
    flexDirection: 'column',
    padding: `0 ${size.S}`,
  },
})

export let headerInnerLoggedIn: Style = css({
  width: '100%',

  maxHeight: mTheme.navbarHeight.desktop,
  gap: size.XS,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',

  [breakpoint.small]: {
    height: mTheme.navbarHeight.mobile,
  },
  [breakpoint.belowLarge]: {
    margin: 0,
  },
  [breakpoint.large]: {
    maxWidth: mTheme.pageWidth.LAccountedForPadding,
    margin: '0 auto 0 auto',
  },
})

export let headerInnerLoggedOut: Style = css({
  width: '100%',
  maxWidth: mTheme.pageWidth.L,
  maxHeight: mTheme.navbarHeight.desktop,
  margin: '0 auto 0 auto',
  backgroundColor: backgroundColor.primary,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',

  [breakpoint.belowMedium]: {
    height: mTheme.navbarHeight.mobile,
  },
})

export let logoSection: Style = css({
  [breakpoint.small]: {
    // paddingTop: size.S,
    width: '100%',
  },
})

export let logo: Style = css({
  display: 'flex',
  marginTop: '100px',
  marginBottom: '-25px',
  justifyContent: 'center',
})

export let feedbackLink: Style = css({
  [breakpoint.small]: {
    display: 'none',
  },
})

export let desktopLeftSection: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: mTheme.size.XS,
  [mTheme.breakpoint.belowMedium]: {
    display: 'none',
  },
})

export let rightSectionLoggedIn: Style = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  boxSizing: 'border-box',
  gap: size.S,
  paddingTop: size.XXS,
  paddingBottom: size.XXS,

  flexGrow: 1,
  [breakpoint.belowMedium]: {
    justifyContent: 'space-between',
  },
  [breakpoint.small]: {
    paddingTop: size.S,
    paddingBottom: size.S,
  },
  [breakpoint.medium]: {
    gap: size.M,
  },
})

export let rightSectionLoggedOut: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  [mTheme.breakpoint.small]: {
    width: '100%',
    justifyContent: 'flex-end',
  },
})

export let selectedProfileButton: Style = css({
  [breakpoint.belowMedium]: {
    display: 'none',
  },
})

export let hidden = css({ display: 'none !important' })

export let hiddenBelowMedium = css({
  [mTheme.breakpoint.belowMedium]: { display: 'none' },
})

export let hiddenAboveMedium = css({
  [mTheme.breakpoint.medium]: { display: 'none' },
})

export let authenticatedHeaderItem = css({
  width: 49,
  height: 40,
  fontSize: fontSize.S,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

export let leftSection = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: mTheme.size.S,
  marginLeft: mTheme.size.S,
  [mTheme.breakpoint.belowMedium]: {
    display: 'none',
  },
})
