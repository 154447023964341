import { inboxNavButtonConfig } from '../../../features/eventInvitation'
import { myTicketsNavButtonConfig } from '../../../features/tickets/ticketRoutes'
import { Profile } from '../../../graphql/types.gen'
import { hiddenAboveMedium } from './Header.styles'
import { HeaderButtonProps } from './HeaderButton/HeaderButton'
import HeaderLogo from './HeaderLogo'

export interface NavButtonConfig extends HeaderButtonProps {
  element?: React.ReactNode
  desktop?: boolean
  overflow?: boolean
  authenticated?: boolean
}

let home: NavButtonConfig = {
  label: 'Home',
  route: '/',
  element: (
    <div css={hiddenAboveMedium}>
      <HeaderLogo size={35} />
    </div>
  ),
  iconName: 'fal fa-newspaper',
}

export let forArtists: NavButtonConfig = {
  label: 'Artists',
  route: '/for-artists',
  overflow: true,
  authenticated: false,
  desktop: true,
}

export let forVenues: NavButtonConfig = {
  label: 'Venues',
  route: '/for-venues',
  overflow: true,
  authenticated: false,
  desktop: true,
}

export let yuptyPromotions: NavButtonConfig = {
  label: 'Promotions',
  route: '/yupty-promotions',
  overflow: true,
  authenticated: false,
  desktop: true,
}

export let pricing: NavButtonConfig = {
  label: 'Pricing',
  route: '/pricing',
  overflow: true,
  authenticated: false,
  desktop: true,
}

let scene: NavButtonConfig = {
  label: 'Scene',
  route: '/scene',
  iconName: 'fa fa-stars',
  beta: 'scene',
  authenticated: true,
  overflow: true,
}

let artists: NavButtonConfig = {
  label: 'Artists',
  route: '/artists',
  iconName: 'fa fa-user',
  authenticated: true,
}

let genres: NavButtonConfig = {
  label: 'Genres',
  route: '/genres',
  iconName: 'fa fa-music',
  authenticated: true,
}

let venues: NavButtonConfig = {
  label: 'Venues',
  route: '/venues',
  iconName: 'fas fa-map-marker-alt',
  authenticated: true,
}

let saved: NavButtonConfig = {
  label: 'Saved',
  route: '/saved',
  iconName: 'fas fa-heart',
  authenticated: true,
  overflow: true,
}

const settings: NavButtonConfig = {
  label: 'Settings',
  route: '/settings/account',
  iconName: 'fa fa-cog',
  overflow: true,
  authenticated: true,
}

let userConfigs: NavButtonConfig[] = [
  home,
  forArtists,
  forVenues,
  yuptyPromotions,
  pricing,
  artists,
  genres,
  venues,
  { ...myTicketsNavButtonConfig, overflow: false },
  saved,
  settings,
  scene,
]

let participantConfigs: NavButtonConfig[] = [
  home,
  forArtists,
  forVenues,
  yuptyPromotions,
  pricing,
  artists,
  venues,
  inboxNavButtonConfig,
  settings,
  { ...genres, overflow: true },
  saved,
  myTicketsNavButtonConfig,
  scene,
]

export let navButtonConfigs: Record<
  NonNullable<Profile['__typename']>,
  NavButtonConfig[]
> = {
  User: userConfigs,
  Artist: participantConfigs,
  Promoter: participantConfigs,
  Venue: participantConfigs,
}

export let loggedOutRightSectionItems: NavButtonConfig[] = [
  {
    label: 'Log in',
    route: '/login',
  },
]
