import * as React from 'react'
import isEqual from 'lodash-es/isEqual'
import { EventFilter } from '../../graphql/types.gen'

let defaultFilter: EventFilter = {
  completed: false,
}

let defaultState: EventFilterState = {
  filter: defaultFilter,
  hasChanged: false,
  setFilter: () => {
    return
  },
}

type EventFilterState = {
  filter: EventFilter
  hasChanged: boolean
  setFilter: React.Dispatch<React.SetStateAction<EventFilter>>
}

let EventFilterContext = React.createContext(defaultState)

export let EventFilterProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  let [filter, setFilter] = React.useState<EventFilter>(defaultFilter)
  let hasChanged = !isEqual(filter, defaultFilter)

  return (
    <EventFilterContext.Provider value={{ filter, setFilter, hasChanged }}>
      {children}
    </EventFilterContext.Provider>
  )
}

export let useEventFilter = () => React.useContext(EventFilterContext)
