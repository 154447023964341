import { SelectProfileButton } from '../../../../features/profileSelector/SelectProfileButton'
import { profileCanBeEventParticipant } from '../../../../features/profileSelector/profileTypeCanBeParticipant'
import { useCurrentUser } from '../../../../graphql/hooks'

type Props = {
  buttonCss?: Style
}

export function SelectProfilesList(props: Props) {
  let { user } = useCurrentUser()

  if (!user || !user.profiles) {
    return null
  }

  let validProfiles = user.profiles.filter(profileCanBeEventParticipant)

  return (
    <>
      {validProfiles.map((profile) => (
        <SelectProfileButton
          key={profile.name}
          profile={profile}
          composeCss={props.buttonCss}
        />
      ))}
    </>
  )
}
