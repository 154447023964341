import { Command } from 'react-command-palette'

import { CommandContext } from './CommandPalette'
import { useLogIn } from '../../utilities/userUtils'

const testUserEmailBases = ['artist', 'venue', 'promoter', 'fan']

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createDebugLoginCommands(context: CommandContext) {
  let [logIn] = useLogIn()
  const commands: Command[] = []

  for (let i = 0; i < 10; i++) {
    for (const base of testUserEmailBases) {
      const name = `${base}${i}`
      const email = `${name}@yupty.live`

      commands.push({
        id: i,
        color: 'pink',
        name: `Log in with ${name}`,
        async command() {
          await logIn({
            variables: {
              loginInput: {
                email,
                password: 'Password123',
              },
            },
          })
        },
      })
    }
  }

  return commands
}

/** Only available in "development" mode */
export function createDevCommands(context: CommandContext) {
  if (context.config.mode !== 'development') {
    return []
  }

  const commands: Command[] = [...createDebugLoginCommands(context)]

  return commands
}
