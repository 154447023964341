import { css } from '@emotion/react'
import { mTheme } from '../../../style/themes'

let { backgroundColor, borderColor, effect, size, breakpoint } = mTheme

export const menuAreaContainer: Style = css({
  backgroundColor: backgroundColor.primary,
  [breakpoint.medium]: {
    display: 'none',
  },
})

export const dropdownMenu: Style = css({
  dispaly: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderBottom: `1px solid ${borderColor.tertiary}`,
})

export const opacityLayer: Style = css({
  backgroundColor: effect.dimBackground,
  height: '1000px',
  left: '-5%',
  width: '120%',
  position: 'absolute',
})

export const menuListContainer: Style = css({
  marginTop: size.S,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: size.S,
})

export const footer: Style = css({
  margin: `${size.S} auto`,
  marginTop: size.M,
  ul: {
    flexDirection: 'column',
  },
})
