import { ApolloClient } from '@apollo/client/index.js'
import ReactCommandPalette, { type Command } from 'react-command-palette'
import { NavigateFunction, useNavigate } from '@remix-run/react'
import { useCurrentUser } from '../../graphql/hooks'
import { RouteConfig } from '../../routes'
import { createDevCommands } from './devCommands'
import { createNavigationCommands } from './navigationCommands'
import { useApolloClient } from '@apollo/client/index.js'
import { logOut } from '../../utilities/userUtils'
import { ClientConfig } from '../../configClient'
import { useRootLoader } from '../../root'

export interface CommandContext {
  navigate: NavigateFunction
  currentUser: ReturnType<typeof useCurrentUser>
  rootRouteConfigs: RouteConfig[]
  apollo: ApolloClient<object>
  config: ClientConfig
}

function createCommands(context: CommandContext) {
  const commands: Command[] = []
  commands.push(...createDevCommands(context))
  commands.push(...createNavigationCommands(context))
  commands.push({
    name: 'Log out',
    command: () => {
      logOut({ client: context.apollo })
    },
  })

  return commands
}

interface PaletteProps {
  routes?: RouteConfig[]
}

export const CommandPalette: React.FC<PaletteProps> = ({ routes }) => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const apollo = useApolloClient()
  let { config } = useRootLoader()

  const context: CommandContext = {
    navigate,
    currentUser,
    rootRouteConfigs: routes ?? [],
    apollo,
    config,
  }

  const commands = createCommands(context)

  return (
    <div css={{ width: '100%' }}>
      <ReactCommandPalette
        closeOnSelect
        resetInputOnOpen
        hotKeys="ctrl+/"
        commands={commands}
        trigger={<span />}
      />
    </div>
  )
}
