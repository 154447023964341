import SvgYuptyLogo from '../../../YuptyLogo'

import * as styles from './HeaderLogo.styles'
import { NavLink } from '@remix-run/react'

interface HeaderLogoProps {
  size: number
}
const HeaderLogo: React.FC<HeaderLogoProps> = ({ size, ...props }) => {
  return (
    <NavLink to="/" css={styles.logo} {...props}>
      <SvgYuptyLogo height={size} />
    </NavLink>
  )
}

export default HeaderLogo
