import { Page } from '../Page/Page'

export function InstagramWebviewWarning() {
  return (
    <Page title="">
      Please tap the three dots in the top right and select "Open in external
      browser".
    </Page>
  )
}
