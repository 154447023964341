import {
  HeaderButtonProps,
  HeaderButton,
} from '../Header/HeaderButton/HeaderButton'
import { InlineFooter } from '../Footer'

import * as styles from './MobileMenu.styles'
import { SelectProfilesList } from '../Header/ProfileButton/SelectProfilesList'

interface MobileMenuProps {
  mobileMenuItems: HeaderButtonProps[]
  isMenuExpanded: boolean
  hideMenu: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  mobileMenuItems,
  isMenuExpanded,
  hideMenu,
}) => {
  if (!isMenuExpanded) return null

  return (
    <div css={styles.menuAreaContainer} onClick={() => hideMenu()}>
      <div css={styles.dropdownMenu} onClick={() => hideMenu()}>
        <div css={styles.menuListContainer}>
          {mobileMenuItems.map((menuItem, index) => (
            <HeaderButton
              key={menuItem.label}
              {...menuItem}
              isFirst={index === 0}
              isInMobileMenu
            />
          ))}

          <SelectProfilesList />
        </div>

        <InlineFooter css={styles.footer} />
      </div>
      <div css={styles.opacityLayer} />
    </div>
  )
}

export default MobileMenu
