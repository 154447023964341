export const countBadge: Style = ({ color, fontColor, size }) => ({
  position: 'absolute',
  right: `-${size.XXS}`,
  top: `-${size.XXS}`,
  minWidth: '5px',
  minHeight: '5px',
  lineHeight: '5px',
  fontWeight: 'bold',
  padding: size.XXS,
  paddingBottom: 6,
  color: fontColor.plain.primary,
  backgroundColor: color.primary,
  fontSize: '10px',
  borderRadius: '20px',
})

export const container: Style = () => ({
  position: 'relative',
  display: 'flex',
})
