import { HeaderButton } from '../../components/global/Header/HeaderButton'
import { useEventInvitations } from './useEventInvitations'
import * as styles from './InboxNavButton.styles'
import { EventInvitationStatus } from '../../graphql/types.gen'
import { NavButtonConfig } from '../../components/global/Header/navConfig'

interface CountBadgeProps {
  count: number
}

export function CountBadge({ count }: CountBadgeProps) {
  return <span css={styles.countBadge}>{count}</span>
}

export const inboxNavButtonConfig: NavButtonConfig = {
  iconName: 'fa fa-inbox',
  label: 'Inbox',
  route: '/inbox',
  element: <InboxNavButton />,
}

export function InboxNavButton() {
  const eventInvitations = useEventInvitations()
  const nonIgnoredInvitations = eventInvitations.filter(
    (invitation) => invitation.status !== EventInvitationStatus.Ignored,
  )

  return (
    <div css={styles.container}>
      <HeaderButton
        iconName={inboxNavButtonConfig.iconName}
        label={inboxNavButtonConfig.label}
        route={inboxNavButtonConfig.route}
      />
      {nonIgnoredInvitations.length > 0 && (
        <CountBadge count={nonIgnoredInvitations.length} />
      )}
    </div>
  )
}
