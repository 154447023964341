import { css } from '@emotion/react'
import { mTheme } from '../../../../style/themes'

export const logo: Style = css({
  display: 'flex',
  justifyContent: 'center',
  width: mTheme.fontSize.XXXL,

  [mTheme.breakpoint.medium]: {
    width: 'fit-content',
  },
})
