import { css } from '@emotion/react'
import { mTheme } from '../../../../style/themes'

export const headerButton: Style = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: mTheme.size.XS,
  padding: mTheme.size.S,
  width: '100%',
  fontSize: mTheme.fontSize.S,
  fontWeight: 'normal',
  background: 'none',
})

export const link: Style = ({
  color,
  fontColor,
  backgroundColor,
  radius,
  breakpoint,
  size,
}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: size.XXS,
  backgroundColor: backgroundColor.card,
  color: fontColor.plain.primary,
  fontWeight: 'bold',
  borderRadius: radius.primary,
  margin: 0,
  padding: `0.25rem 0.5rem`,
  ':visited': {
    color: fontColor.plain.secondary,
  },
  ':hover': {
    color: fontColor.plain.tertiary,
    backgroundColor: backgroundColor.innerCard,
  },
  '&.active': {
    color: color.primary,
  },

  [breakpoint.belowMedium]: {
    width: '100%',
    fontWeight: 'bold',
    padding: size.S,
  },
})

export const thumbnail: StyleWithOptions<string> =
  (imageUrl) =>
  ({ size, breakpoint }) => ({
    zIndex: 10,
    width: size.L,
    height: size.L,
    borderRadius: '1em',
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',

    [breakpoint.belowMedium]: {
      width: size.S,
      height: size.S,
    },
  })

export const chevron: Style = css({
  color: mTheme.fontColor.plain.tertiary,
  position: 'relative',
  top: 2,
})
