import { NavLink } from '@remix-run/react'
import { Beta } from '../../../../utilities/useBeta'
import { RemixLinkProps } from '@remix-run/react/dist/components'

import * as styles from './HeaderButton.styles'
import { Button } from 'react-aria-components'
import { authenticatedHeaderItem } from '../Header.styles'

export interface HeaderButtonProps {
  iconName?: string
  label: string
  route: string
  isInMobileMenu?: boolean
  isFirst?: boolean
  beta?: Beta
  composeCss?: Style
  prefetch?: RemixLinkProps['prefetch']
}

export const HeaderButton: React.FC<HeaderButtonProps> = ({
  iconName,
  label,
  route,
  isInMobileMenu = false,
  isFirst = false,
  composeCss,
  prefetch,
}) => {
  return (
    <NavLink
      to={route}
      css={[styles.link({ isInMobileMenu, isFirst }), composeCss]}
      prefetch={prefetch}
    >
      {iconName && <i css={styles.icon(isInMobileMenu)} className={iconName} />}
      <span css={styles.label(isInMobileMenu)}>{label}</span>
    </NavLink>
  )
}

interface MenuButtonProps {
  isOpen: boolean
  toggle: () => void
  children?: React.ReactNode
  hideIcon?: boolean
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  isOpen,
  toggle,
  children,
  hideIcon,
}) => {
  return (
    <Button
      css={[styles.link({}), authenticatedHeaderItem]}
      onPress={() => toggle()}
    >
      {!hideIcon && (
        <i
          css={styles.icon(false)}
          className={isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
        />
      )}
      <span css={styles.label(false)}>{isOpen ? 'Less' : 'More'}</span>
      {children}
    </Button>
  )
}
