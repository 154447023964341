import { css } from '@emotion/react'

import { mTheme } from './themes'

export const globalStyles: Style = css({
  '*': {
    boxSizing: 'border-box',
  },
  body: {
    backgroundColor: mTheme.backgroundColor.primary,
    color: mTheme.fontColor.plain.primary,
    fontFamily: mTheme.font.primary,
    margin: 0,
  },
  a: {
    textDecoration: 'none',
    color: mTheme.fontColor.link.normal,
  },
  'a:hover': {
    color: mTheme.fontColor.link.hover,
  },
  'a:visited': {
    color: mTheme.fontColor.link.visited,
  },
  button: {
    color: mTheme.fontColor.plain.primary,
    background: 'inherit',
    border: 'inherit',
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  h1: {
    fontSize: mTheme.size.L,
  },
  h2: {
    fontSize: mTheme.size.M,
  },
  'h1, h2, h3, h4, h5, h6, p': {
    width: '100%',
    textAlign: 'left',
    marginTop: mTheme.size.S,
    marginBottom: mTheme.size.S,
    fontFamily: mTheme.font.primary,
  },

  ':focus-visible': {
    outline: `2px solid ${mTheme.color.focusRing}`,
    outlineOffset: mTheme.size.XXS,
    borderRadius: mTheme.radius.secondary,
  },

  /* Chrome, Safari, Edge, Opera */
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  /* Firefox */
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
})
